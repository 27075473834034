<template>
    <span class="cs-media">
        <template v-if="mediaTypes.video.includes(type) && (support.length ==0 || support.includes('video'))">
            <video :controls="controls" :autoplay="autoplay" :loop="loop" :muted="muted">
                <source :src="data" :type="`video/${this.type}` " />
            </video>
        </template>
        <template v-else-if="mediaTypes.img.includes(type) && (support.length ==0 || support.includes('image'))">
            <img :src="data">
        </template>
        <template v-else-if="mediaTypes.pdf.includes(type) && (support.length ==0 || support.includes('pdf'))">
            <embed :src="data" />
        </template>
    </span>
</template>

<script>

import axios from 'axios'

if(typeof window != 'undefined'){
    if(window.location.port != '') axios.defaults.baseURL = window.location.origin;
}

export default {
    name: 'CSMedia',
    props: {
    	data: {
        	type:String,
        	default: ''
        },
        support:{
            type:Array,
            default: () => {
                return []
            }
        },
        controls:{
            type:Boolean,
            default: false
        },
        autoplay:{
            type:Boolean,
            default: false
        },
        loop:{
            type:Boolean,
            default: true
        },
        muted:{
            type:Boolean,
            default: true
        }
    },
    data(){
        return {
            mediaTypes: {
                video: ['mp4', 'm4v','ogv','flv','webm','asf','avi'], 
                img: ['apng','avif','gif','jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp','png','svg','webp'],
                // ['3d']: ['obj','glb','mtl'],
                pdf:['pdf']
            },
            type:''
        }
    },
    methods: {},
    mounted(){
        let self = this
        if(typeof window != 'undefined'){
            if(this.data){
                let res
                try{
                    axios.head(this.data).then(res =>{
                        if(res){
                            self.type = res.headers['media-type']
                        }
                    })
                }catch(e){}
            }
        } 
    }
}
</script>



<style lang="scss" scoped>
    .cs-media{
        video{
            object-fit: cover;
        }
        
    }
</style>
